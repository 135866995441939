import styled from 'styled-components'

const backgroundColor = ``
const maxHeight = '50vh'
const mobileHeight = `40vh`
const textMobileHeight = `10vh`
const navMargin = '10px'

export const CarouselStyles = styled.div`
  width: 100%;
  height: ${maxHeight};
  min-height: 300px;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid ${backgroundColor};
  background-color: ${backgroundColor};

  @media all and (min-width: 700px) {
  }

  @media all and (min-width: 1136px) {
  }
`
export const NavStyles = styled.div`
  box-shadow: 0px 0px 5px #444444;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  bottom: 50%;
  transform: translateY(50%);

  position: absolute;
  background: #ffffff;
  z-index: 9900;
  cursor: pointer;
  pointer-events: auto;

  &.left {
    left: ${navMargin};
  }

  &.right {
    right: ${navMargin};
  }

  .nav {
    font-size: 4rem;
    position: absolute;
    left: 50%;
    top: 50%;

    &.navLeft {
      transform: translate(-55%, -51%);
    }

    &.navRight {
      transform: translate(-47%, -51%);
    }
  }

  @media all and (min-width: 700px) {
    font-size: 2.3rem;
  }

  @media all and (min-width: 1136px) {
    bottom: 20px !important;
    transform: translateY(0);
  }

  @media all and (min-width: 1136px) {
    font-size: 3rem;
  }
`
export const nDotStyles = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;

  .nDot {
    border: 1px solid #444444;
    height: 12px;
    width: 12px;
    background-color: #000000;
    border-radius: 100%;
    margin-bottom: 1px;

    pointer-events: auto;
    cursor: pointer;
  }
`
export const TimerStyles = styled.div`
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: ${backgroundColor};
  z-index: 999;

  #inner-timer {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #65b0aa;
  }
`
export const AllImageStyles = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: ${maxHeight};
  cursor: none;

  .imageArea a {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: ${maxHeight};
    pointer-events: auto;
    cursor: pointer;
  }
`
export const ImageStyles = styled.div.attrs(({ props }) => {
  const { cover } = props

  return {
    coverType: cover ? 'cover' : 'contain',
  }
})`
  width: 100%;
  height: ${mobileHeight};
  pointer-events: auto;
  cursor: pointer;
  position: absolute;

  .container {
    height: ${mobileHeight};
    width: 100%;
    top: 50%;
    bottom: 50%;

    .image {
      background-color: ${backgroundColor};
      height: ${mobileHeight};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      position: absolute;
      top: 20px;
      max-height: ${mobileHeight};
      object-fit: cover !important;
    }
  }

  @media all and (min-width: 700px) {
    height: ${maxHeight};

    .container {
      height: ${maxHeight};

      .image {
        height: ${maxHeight};

        img {
          max-height: ${maxHeight};

          object-fit: ${props => `${props.coverType} !important`};
        }
      }
    }
  }
`
export const ContentStyles = styled.div`
  z-index: 80;
  position: absolute;
  height: ${textMobileHeight};
  width: 100%;
  text-align: center;
  font-size: 80%;
  padding: 0;
  pointer-events: none;
  cursor: auto;
  top: 40vh;
  overflow: hidden;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
    /* margin: 0 auto; */
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 5px 10px;
  }

  h2 {
    text-decoration: underline;
    margin-bottom: 10px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  h4 {
    line-height: 1.3;
  }

  @media all and (min-width: 700px) {
    width: 100%;

    /* border-bottom: 3px solid black; */

    font-size: 100%;

    .content {
      padding: 20px 15px;
      /* position: absolute; */
      margin-bottom: 10px;
    }
  }

  @media all and (min-width: 1136px) {
    height: ${maxHeight};
    position: absolute;
    top: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 30px;

    .content {
      height: auto;
      padding: 30px 15px;

      text-align: left;
    }
    &.right {
      align-items: center;
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }
  }
`
