import React, { Component } from 'react'
import gsap from 'gsap'
import Img from 'gatsby-image'

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { imageLinksHandler } from '../../utils/handleRichTextLinks'
import {
  CarouselStyles,
  NavStyles,
  nDotStyles as NDotStyles,
  ImageStyles,
  AllImageStyles,
  ContentStyles,
  TimerStyles,
} from './carouselStyles'

class Carousel extends Component {
  constructor({ elements }) {
    super()
    this.elements = elements
    this.mainContainer = null
    this.imageElements = null
    this.nDotElements = null
    this.contentElements = []
    this.prevNav = null
    this.nextNav = null
    this.lineContainer = null
    this.timeline = gsap.timeline()
    this.canAuto = false
    this.canRotate = true

    this.imageArr = []
    this.nDotArr = []
    this.contentArr = []
    this.currentIndex = 0

    this.stats = {
      transitionDuration: 1,
      onDuration: 5,
      ease: 'power3.inOut',
      type: 'slide',
    }
  }

  componentDidMount() {
    this.gsapSetup()
    this.addEvents()
    if (this.imageArr.length > 1) {
      this.startAutoplay()
    } else {
      this.removeLine()
      this.removeNav()
    }
  }

  componentDidUpdate() {
    this.currentIndex = 0

    if (this.imageArr.length > 1) {
      this.startAutoplay()
    } else {
      this.removeLine()
      this.removeNav()
    }
  }

  componentWillUnmount() {
    this.timeline.pause()
  }

  showPrev() {
    this.timeline.kill()
    this.removeLine()
    let toIndex = this.currentIndex - 1
    toIndex = toIndex < 0 ? this.imageArr.length - 1 : toIndex

    this.showIndex(toIndex, -1)
  }

  showNext() {
    this.timeline.kill()
    this.removeLine()
    let toIndex = this.currentIndex + 1
    toIndex = toIndex >= this.imageArr.length ? 0 : toIndex

    this.showIndex(toIndex, 1)
  }

  // setup
  gsapSetup() {
    this.deltaX = this.mainContainer.clientWidth

    const handleNavs = () => {
      const { prevNav, nextNav } = this

      prevNav.addEventListener('click', () => {
        this.showPrev()
      })

      nextNav.addEventListener('click', () => {
        this.showNext()
      })
    }

    const imageSetup = () => {
      this.imageArr.forEach((img, i) => {
        gsap.set(img, { zIndex: i === 0 ? 1 : 0 })
      })
    }

    handleNavs()
    imageSetup()
  }

  handleImages() {
    if (!this.imageElements) {
      const imageElements = this.elements.map((imageInfo, i) => {
        const contentElement = this.handleContent(i)

        const imageDiv = (
          <ImageStyles
            props={{
              cover: imageInfo.coverImage,
            }}
          >
            <div className="container">
              <Img
                fluid={imageInfo.image.asset.fluid}
                alt={imageInfo.alt}
                className="image"
              />
            </div>
          </ImageStyles>
        )

        const LinkedImage = imageLinksHandler(imageInfo.link, imageDiv)

        return (
          <div
            key={imageInfo.id}
            className="imageArea"
            ref={div => this.imageArr.push(div)}
          >
            {LinkedImage}
            {contentElement}
          </div>
        )
      })
      this.imageElements = imageElements
    }

    return this.imageElements
  }

  handleContent(index) {
    if (!this.contentElements[index]) {
      const el = this.elements[index]
      const { title, description } = el

      if (!!title || !!description) {
        const descriptionEl = description ? (
          <h4 key={`${el.id}subtitle`}>{description}</h4>
        ) : (
          ''
        )

        const titleEl = title ? (
          <h2
            key={`${el.id}headline`}
            className={!description ? 'no-margin' : ''}
          >
            {title}
          </h2>
        ) : (
          ''
        )

        const addedClass = el.displayLeft ? 'left' : 'right'

        return (
          <ContentStyles
            className={`${addedClass}`}
            key={`${el.id}-content`}
            ref={div => this.contentArr.push(div)}
          >
            <div className="content">
              {titleEl}
              {descriptionEl}
            </div>
          </ContentStyles>
        )
      }

      return (
        <div
          key={`${el.id}-nocontent`}
          ref={div => (this.contentArr[index] = div)}
        />
      )
    }

    return this.contentElements
  }

  addNDots() {
    if (!this.nDotElements && this.elements.length > 1) {
      this.nDotElements = this.elements.map(img => (
        <div
          key={`${img.id}-ndot`}
          className="nDot"
          ref={div => this.nDotArr.push(div)}
        />
      ))
    }
    return this.nDotElements
  }

  addEvents(add = true) {
    const hoverEventEls = [...this.imageArr, this.nextNav, this.prevNav]

    const prop = add ? 'addEventListener' : 'removeEventListener'

    hoverEventEls.forEach(el => {
      el[prop]('mouseenter', () => this.timeline.pause())
      el[prop]('mouseout', () => this.timeline.play())
    })

    this.nDotArr.forEach((dot, i) => {
      dot[prop]('click', () => {
        this.timeline.kill()

        const dir = i < this.currentIndex ? -1 : 1

        this.showIndex(i, dir)
      })
    })

    // document[prop](
    //   'keydown',
    //   e => {
    //     const { key } = e
    //     if (key === 'ArrowLeft') {
    //       e.preventDefault()
    //       this.showPrev()
    //     }

    //     if (key === 'ArrowRight') {
    //       e.preventDefault()
    //       this.showNext()
    //     }
    //   },
    //   true
    // )

    window[prop]('resize', () => {
      this.showIndex(this.currentIndex, 1, 0)
    })
  }

  removeLine() {
    this.canAuto = false
    const line = this.lineContainer

    if (line) {
      line.style.display = 'none'
    }
  }

  removeNav() {
    this.canAuto = false
    const { prevNav, nextNav } = this

    prevNav.style.display = 'none'
    nextNav.style.display = 'none'
  }

  // playing and changing index
  startAutoplay() {
    this.canAuto = true
    const images = this.imageArr.length
    const delay = this.stats.onDuration

    this.timeline.kill()
    this.timeline = gsap.timeline()

    const { timeline } = this

    this.showIndex(0, 1, 0, 1)

    const animateLine = (tl, index) => {
      tl.set('#inner-timer', {
        x: '-100%',
      })

      timeline.to('#inner-timer', {
        duration: delay,
        x: 0,
        ease: 'power1.inOut',
        onComplete: () => {
          if (this.canAuto) {
            this.showIndex(index, 1)
          }
        },
      })
    }

    for (let i = 1; i < images; i += 1) {
      animateLine(timeline, i)
    }

    animateLine(timeline, 0)

    timeline.add(() => {
      this.removeLine()
    })
  }

  showIndex(toIndex, direction = 1, overrideDuration = '', autoOverride = 0) {
    let {
      timeline,
      imageArr,
      nDotArr,
      stats,
      currentIndex,
      mainContainer,
    } = this
    const { ease, transitionDuration, type } = stats

    if (!this.canRotate) {
      return
    }

    this.canRotate = false

    const duration =
      overrideDuration !== '' ? overrideDuration : transitionDuration

    timeline = gsap.timeline()

    const animationTypes = {
      fade: () => {
        imageArr.forEach((img, i) => {
          if (i === toIndex && img) {
            timeline.to(img, { duration, autoAlpha: 1, ease }, 0)
          } else if (img) {
            timeline.to(img, { duration, autoAlpha: 0, ease }, 0)
          }
        })
      },
      reveal: () => {
        const xDelta = -mainContainer.clientWidth * direction

        imageArr.forEach((img, i) => {
          if (i === currentIndex) {
            timeline.set(img, { zIndex: 2 })
            timeline.to(img, { duration, ease, x: xDelta }, 0.1)
          } else if (i === toIndex) {
            timeline.set(img, { x: 0, zIndex: 1 }, 0)
          } else {
            timeline.set(img, { x: 0, zIndex: 0 }, 0)
          }
        })

        timeline.set(imageArr[toIndex], { zIndex: 2 })
        timeline.set(imageArr[currentIndex], { zIndex: 1 })
        timeline.set(imageArr, { x: 0 })
      },
      slide: () => {
        const xDelta = -mainContainer.clientWidth * direction

        imageArr.forEach((img, i) => {
          if (i === currentIndex) {
            timeline.set(img, { zIndex: 2, x: 0, autoAlpha: 1 }, 0)
            timeline.to(img, { duration, ease, x: xDelta }, 0)

            if (toIndex !== currentIndex) {
              timeline.set(img, { zIndex: 0, autoAlpha: autoOverride })
            }
          } else if (i === toIndex) {
            timeline.set(img, { zIndex: 1, x: -xDelta, autoAlpha: 1 }, 0)
            timeline.to(img, { ease, duration, x: 0 }, 0)
          } else {
            timeline.set(img, { x: 0, zIndex: 0, autoAlpha: 0 }, 0)
          }
        })
      },
    }

    animationTypes[type]()

    const nDotEase = 'power1.out'
    nDotArr.forEach((dot, i) => {
      if (i === toIndex && dot) {
        timeline.to(
          dot,
          {
            duration,
            scale: 1.1,
            y: 0,
            ease: nDotEase,
            backgroundColor: '#000',
            border: '1px solid #999999',
          },
          0
        )
      } else if (dot) {
        timeline.to(
          dot,
          {
            duration,
            scale: 0.8,
            ease: nDotEase,
            backgroundColor: '#ffffff',
            border: '1px solid #999999',
          },
          0
        )
      }
    })

    timeline.add(() => {
      this.canRotate = true
    })

    this.currentIndex = toIndex
  }

  render() {
    return (
      <CarouselStyles
        id="main-container"
        ref={div => (this.mainContainer = div)}
      >
        <NavStyles
          className="navContainer left"
          ref={div => (this.prevNav = div)}
        >
          <AiOutlineLeft className="nav navLeft" />
        </NavStyles>
        <NavStyles
          className="navContainer right"
          ref={div => (this.nextNav = div)}
        >
          <AiOutlineRight className="nav navRight" />
        </NavStyles>
        <NDotStyles>{this.addNDots()}</NDotStyles>
        <AllImageStyles id="allImgContainer">
          {this.handleImages()}
        </AllImageStyles>
        <TimerStyles>
          <div id="timer" ref={div => (this.lineContainer = div)}>
            <div id="inner-timer" />
          </div>
        </TimerStyles>
      </CarouselStyles>
    )
  }
}

const isCurrentlyRunning = el => {
  const now = new Date()
  const { startDate, endDate } = el

  const pastStart = new Date(startDate) - now < 0
  const pastEnd = endDate ? new Date(endDate) - now < 0 : false

  return pastStart && !pastEnd
}

const getFinalElements = elements => {
  const { priorityEls, normalEls } = elements.reduce(
    (obj, curEl) => {
      if (isCurrentlyRunning(curEl)) {
        const { isPriority } = curEl

        if (isPriority) {
          obj.priorityEls.push(curEl)
        } else {
          obj.normalEls.push(curEl)
        }
      }

      return obj
    },
    {
      priorityEls: [],
      normalEls: [],
    }
  )

  return [...priorityEls, ...normalEls]
}

export default function createCarousel({ elements, location }) {
  const finalElements = getFinalElements(elements)

  return <Carousel elements={finalElements} location={location} />
}
