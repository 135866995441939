import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import Carousel from '../components/carousel/carousel'
import ProcessSections from '../utils/processSections'

const IndexStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  min-height: clear;
  /* height: 100vh; */
`

const SectionListStyles = styled.div``

export default function HomePage({ data, location }) {
  const sections = data.pageContent.nodes[0]

  return (
    <>
      <Carousel elements={data.carousel.nodes} location={location} />
      <IndexStyles className="content-width">
        <ProcessSections
          sections={sections}
          SectionStyles={SectionListStyles}
        />
      </IndexStyles>
    </>
  )
}

export const getCarousel = graphql`
  query CarouselQuery {
    carousel: allSanityCarousel(sort: { fields: startDate, order: DESC }) {
      nodes {
        id
        startDate(formatString: "MM/DD/YYYY")
        endDate(formatString: "MM/DD/YYYY")
        title
        description
        displayLeft
        alt
        isPriority
        coverImage
        image {
          asset {
            fluid(maxWidth: 1200) {
              ...GatsbySanityImageFluid
            }
          }
        }
        link {
          externalLink {
            blank
            href
          }
          internalLink: _rawInternalLink(resolveReferences: { maxDepth: 1 })
        }
      }
    }
    pageContent: allSanityPageContent(filter: { location: { eq: "/home" } }) {
      nodes {
        id
        content: _rawContent
        location
        title
        displayTitle
      }
    }
  }
`
